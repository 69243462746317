import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.timeout = null
  }

  submit(event) {
    const inputElement = event.target;

    if (inputElement.type === "search") {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.element.requestSubmit()
      }, 650)
    } else {
      this.element.requestSubmit()
    }
  }
}
