import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ['elements', 'suggestionsSelect'];

    disable_check(e) {
        e.target.closest()
    }

    check_radio(event) {
        document.querySelector(`#${event.params.checkBoxId}`).checked = true;
    }

    click_link(event) {
        document.querySelector(`#${event.params.linkId}`).click();
    }

    fetchData(inputValue) {
        const url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";
        const token = "226d4a6e74304a9809013aabf341ff5d7a04285f";

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            },
            body: JSON.stringify({ query: inputValue })
        };

        return fetch(url, options)
            .then(response => response.json())
            .then(data => data.suggestions)
            .catch(error => {
                return [];
            });
    }

    async fetch_company(event) {
        const input = event.target;
        if (input.name === "inn") {
            const inputValue = input.value.trim();

            if (inputValue !== "") {
                const suggestions = await this.fetchData(inputValue);
                this.renderSuggestions(suggestions);
            }
        }
    }

    renderSuggestions(suggestions) {
        const select = this.suggestionsSelectTarget;
        select.innerHTML = "";

        suggestions.forEach((suggestion) => {
            const option = document.createElement("option");
            option.value = suggestion.data.inn;
            option.textContent = `${suggestion.value} (${suggestion.data.inn})`;
            select.appendChild(option);
        });

        select.dataset.suggestions = JSON.stringify(suggestions); // Store the suggestions data in the data-suggestions attribute
        select.classList.remove('hidden');
    }

    selectChanged() {
        const select = this.suggestionsSelectTarget;
        const selectedOption = select.options[select.selectedIndex];
        const selectedInn = selectedOption.value;

        const suggestions = JSON.parse(select.dataset.suggestions); // Retrieve the stored suggestions data

        const suggestion = suggestions.find((suggestion) => suggestion.data.inn === selectedInn);
        if (suggestion) {
            const form = select.closest("form");
            console.log(suggestion);
            form.querySelector("#company").value = suggestion.data.name.full;
            form.querySelector("#inn").value = suggestion.data.inn;
            if ( suggestion.data.phone ) {
                form.querySelector("#phone_number").value = suggestion.data.phone;
            }
            form.querySelector("#address").value = suggestion.data.address.value;
            form.querySelector("#postal_code").value = suggestion.data.address.data.postal_code;
            form.querySelector("#kpp").value = suggestion.data.kpp;
            select.classList.add('hidden');
        }
    }

    getSuggestionByInn(inn) {
        const suggestions = this.suggestionsSelectTarget.dataset.suggestions;
        if (suggestions) {
            const parsedSuggestions = JSON.parse(suggestions);
            return parsedSuggestions.find((suggestion) => suggestion.data.inn === inn);
        }
        return null;
    }

    validateFields(event) {
        event.preventDefault(); // Prevent form submission by default

        let formValid = true;

        const requiredFields = this.element.querySelectorAll(".required" );

        requiredFields.forEach((field) => {
            if (field.closest('.invisible')) {
                // Skip validation for fields within invisible elements
                return;
            }
            if (field.value === undefined || field.value.trim() === "") {
                field.classList.add("border", "border-red-500");
                formValid = false;
            } else if (field.type === 'checkbox' && !field.checked) {
                field.parentNode.classList.add('border', 'border-primary', 'rounded-md');
                formValid = false;
            }
            else {
                field.classList.remove( "border-red-500");
            }
        });

        if (formValid) {
            // Submit the form if all required fields are filled
            this.element.submit();
        }
    }
}
