import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

export default class extends Controller {
    connect() {
        const swiper = new Swiper(this.element, {
            modules: [Pagination],

            spaceBetween: 20,
            slidesPerView: 'auto',
            pagination: {
                el: ".delivery-swiper-pagination",
                clickable: true,
                centered: true,
                bulletClass: "fr-swiper-pagination-bullet",
                bulletActiveClass: "fr-swiper-pagination-bullet-active",
                centeredSlides: true,
                centeredSlidesBounds: true,
                /*breakpoints: {
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 40
                    }
                }*/
            }
        })
    }
}
