import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["tab", "content"];

    connect() {
        const hash = window.location.hash.replace('#', '');
        const initialIndex = this.tabTargets.findIndex(tab => tab.hash === `#${hash}`);
        this.showTab(initialIndex !== -1 ? initialIndex : 0);
    }

    changeTab(event) {
        event.preventDefault();
        const index = this.tabTargets.indexOf(event.currentTarget);
        window.location.hash = this.tabTargets[index].hash;
        this.showTab(index);
    }

    showTab(index) {
        this.tabTargets.forEach((tab, i) => {
            tab.classList.toggle("tab-active", i === index);
            tab.parentNode.classList.toggle('border-b', i !== index);
            if (i === index) {
                this.contentTargets[i].removeAttribute("hidden");
                this.tabTargets[i].classList.add('font-bold');
            } else {
                this.tabTargets[i].classList.remove('font-bold')
                this.contentTargets[i].setAttribute("hidden", true);
            }
        });
    }
}
