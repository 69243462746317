import Reveal from 'stimulus-reveal-controller'

export default class extends Reveal {
    static targets = [ "item", "image" ]

    connect() {
        super.connect();
        const id = window.location.hash.substring(1);
        if (id !== "" && this.itemTarget.dataset.id === id) {
            this.toggle();
            this.itemTarget.classList.remove(this.class);
            }
    }

    toggle() {
        super.toggle();
        this.imageTargets.forEach((s) => {
            s.classList.toggle(this.class);
        });
    }
}
