import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [];

    connect() {
    }

    load_form(event) {
        const type_selection = document.getElementById('client_data_type');
        if (event.target.value !== 'RU') {
            const frame = document.getElementById('client_data');
            type_selection.classList.add('hidden');
            frame.src = '/cart?client_data=personal';
            frame.reload();
        }
        else {
            type_selection.classList.remove('hidden');
        }
    }
}
