import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['message']


    connect() {
    }

    showMessage() {
        // Ensure the element is visible first
        this.messageTarget.classList.remove('hidden');

        // Trigger a reflow to restart the transition
        this.messageTarget.offsetWidth;

        // Make the element fully opaque
        this.messageTarget.classList.remove('opacity-0');
        this.messageTarget.classList.add('opacity-100');

        // Delay to allow the message to be seen before starting fade out
        setTimeout(() => {
            this.messageTarget.classList.remove('opacity-100');
            this.messageTarget.classList.add('opacity-0');
        }, 2000); // Keep message visible for 2 seconds before fading out

        // Hide element after transition
        setTimeout(() => {
            this.messageTarget.classList.add('hidden');
        }, 3000); // 1 second for fade-out transition

        let redDot = document.getElementById('cart-has-items')
        redDot.classList.remove('hidden')
    }
}
