import { Controller } from "@hotwired/stimulus"
import { post } from "@rails/request.js";

export default class extends Controller {
    static targets = ['product_row', 'quantity', 'discount', 'product_total', 'total'];
    static values = {
        deleteConfirm: String,
        errorMessage: String,
        locale: String
    }

    connect() {
    }

    find_field(source, id) {
        return source.find((field) =>
            field.dataset.productId == id
        )
    }

    async change_quantity(event) {
        const quantity_field = this.find_field(this.quantityTargets, event.params.productId);
        let price = parseFloat(event.params.productPrice);
        let quantity = parseInt(quantity_field.value);
        if (event.params.action === 'increment') {
            quantity += 1
            await this.add_to_cart(event.params.productId);
        }
        else {
            quantity -= 1;
            if (quantity === 0) {
                this.delete_product(event.params.productId);
                return
            }
            this.delete_from_cart(event.params.productId);
        }
            quantity_field.value = quantity;
            let discount = this.update_discount(event.params.productId, quantity, price);
            this.update_product_total(event.params.productId, quantity, price, discount);
            this.update_total();
    }

    update_discount(product_id, quantity, price) {
        const discount_field = this.find_field(this.discountTargets, product_id);
        let discount_percent = parseFloat(discount_field.dataset.discountPercent);
        let discount = (price * quantity * discount_percent) / 100;
        discount_field.textContent = this.format_price_value(discount);
        return discount;
    }

    update_product_total(product_id, price, quantity, discount) {
        const product_total_field = this.find_field(this.product_totalTargets, product_id);
        let product_total = quantity * price - discount;
        product_total_field.dataset.product_total_value = product_total
        product_total_field.textContent = this.format_price_value(product_total);
    }

    update_total() {
        let sum = 0;
        this.product_totalTargets.forEach((field) =>
            {
                let field_value = parseFloat(field.dataset.product_total_value);
                sum += field_value;
            }
        )
        this.totalTarget.textContent = this.format_price_value(sum);
    }

    delete_product(product_id) {
        let confirmed = confirm(this.deleteConfirmValue);
        if (confirmed) {
            const row = this.find_field(this.product_rowTargets, product_id);
            this.delete_from_cart(product_id);
            row.remove();
            this.update_total();
        }
    }

    delete_from_cart(product_id) {
        post(`/cart/delete_from_cart/${product_id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                alert(this.errorMessageValue);
                throw error;
            });
    }

    async add_to_cart(product_id) {
        post(`/cart/add_to_cart/${product_id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
            })
            .catch(error => {
                alert(this.errorMessageValue);
                throw error;
            });
    }

    format_price_value(value) {
        return value.toLocaleString(this.localeValue, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }
}
