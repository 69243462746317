import { Controller } from "@hotwired/stimulus"
import SimpleLightbox from "simple-lightbox";
import 'simple-lightbox/dist/simpleLightbox.min.css'

export default class extends Controller {

  connect() {
    this.resourceLoader = this.resourceLoader.bind(this);
    this.isLoaded = false;

    this.loadGalleryItems();
  }

  loadGalleryItems() {
    const galleryItems = this.element.querySelectorAll('.gallery');
    galleryItems.forEach(item => {
      item.addEventListener('click', this.openLightbox.bind(this));
    });
  }

  openLightbox(event) {
    event.preventDefault();

    const galleryItems = Array.from(this.element.querySelectorAll('.gallery'));
    const target = event.currentTarget;
    const items = galleryItems.map(item => item.href);
    const index = galleryItems.indexOf(target);

    this.resourceLoader(() => {
      SimpleLightbox.open({
        items: items,
        startAt: index,
        bindToItems: false
      });
    });
  }

  resourceLoader(callback) {
    if (this.isLoaded) {
      callback();
    } else {
      import("simple-lightbox").then(() => {
        this.isLoaded = true;
        callback();
      });
    }
  }
}
