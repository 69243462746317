import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';
import 'swiper/css';

export default class extends Controller {
    connect() {
        const swiper = new Swiper(this.element, {
            modules: [Autoplay],
            spaceBetween: 30,
            slidesPerView: "auto",
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            }
        })
    }
}
