// app/javascript/controllers/highlight_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    async connect() {
        // Dynamically load the highlight.js library
        const hljs = await import('highlight.js');
        this.loadHighlightCSS();
        this.highlightCode(hljs);
    }

    highlightCode(hljs) {
        this.element.querySelectorAll('pre > code').forEach((block) => {
            hljs.highlightBlock(block);
        });
    }

    loadHighlightCSS() {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://cdnjs.cloudflare.com/ajax/libs/highlight.js/11.9.0/styles/default.min.css';
        document.head.appendChild(link);
    }
}
